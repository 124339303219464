export default {
  SEARCH_BOX_INPUT_CLASS: 'search__input field__input',
  SEARCH_BOX_DIALOG_OPENING_ICON_CLASS: 'header__icon header__icon--search link focus-inset',

  SELECT_YOUR_VEHICLE: 'SELECT YOUR VEHICLE',
  SELECT_YOUR_VEHICLE_BLOCK: 'SELECT YOUR <span>VEHICLE</span>',
  VERIFY_FITMENT_TITLE: 'VERIFY FITMENT WITH YOUR VEHICLE',

  PRODUCT_REPEATER_CLASS: 'grid product-grid grid--2-col-tablet-down grid--3-col-desktop',
};
